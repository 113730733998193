import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
// Components
import {
  Loading,
  PageHeader,
  Primary,
  ProductCard,
  Seo,
  ShoppingWithBanner,
} from '../components'
// Context
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
// Services
import { Magento } from '../services/magento'
// Styles
import { ProductsGrid } from '../styles/LandingPage.styles'
import { Container, HorizontalLine } from '../styles/LoginPage.styles'

const AMB_ENROLL_CATEGORY = 'ambenroll'

const StarterPacksPage = () => {
  const [starterPacks, setStarterPacks] = useState(null)
  const {
    prismicData: {
      prismicProductsPage: { products, loading_products },
    },
  } = usePrismic()
  const { isAmbOfferInCart } = useCartContext()

  useEffect(() => {
    if (!isAmbOfferInCart) navigate('/')
    ;(async () => {
      await Magento.Store.getSingleCategoryData({
        url_key: AMB_ENROLL_CATEGORY,
      })
        .then(({ categories }) => {
          let category = categories.items[0]
          setStarterPacks(category)
        })
        .catch(e => console.log(e))
    })()
  }, [])

  if (!starterPacks)
    return <Loading loading={true} message={loading_products[0].text} />

  return (
    <>
      <Seo title={products[0].text} />
      <ShoppingWithBanner />
      <PageHeader exitRoute="/">Start with a Pack</PageHeader>
      <ProductsGrid>
        {starterPacks?.products.items.map(product => (
          <ProductCard key={product.uid} {...product} />
        ))}
      </ProductsGrid>
      <Container direction="column" padding="0.5em 1em">
        <HorizontalLine>
          <span>OR</span>
        </HorizontalLine>
        <Primary
          content="Pick your own products"
          onClick={() => navigate('/products')}
        />
      </Container>
    </>
  )
}

export default StarterPacksPage
